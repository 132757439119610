<template>
  <div class="offered">
    <div class="offered-title">
      <back-button
        :text="$t('components.offered.button.back')"
        @click="backPage()"
      />
      <span class="page">{{ $t('components.offered.title') }}</span>
      <cart-button />
    </div>
    <card-offered
      :offered="offered"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CardOffered from '@/components/cards/offered/CardOffered.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import CartButton from '@/components/buttons/CartButton.vue';

export default {
  name: 'Offered',
  components: {
    CardOffered,
    BackButton,
    CartButton,
  },
  data() {
    return {
      itemsCart: 0,
    };
  },
  computed: {
    ...mapGetters({
      offered: 'getCreditOffered',
    }),
  },
  mounted() {
    this.fetchCreditOffered(this.$route.params.id);
  },
  methods: {
    ...mapActions([
      'fetchCreditOffered',
    ]),

    backPage() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.offered {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  padding: 2em 2em 2em 16em;
  flex-direction: column;

  .offered-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 50px;

    .page {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
</style>
